<template>
  <div id='payment-finalization-screen'>
    <div class="container webshop-container">
      <header>
        <h1 class="title">{{ $t('pages.payment.finalization.title') }}</h1>
      </header>
      <div class="content-section">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <component
            v-bind="currentStateComponent.bind"
            v-bind:is="currentStateComponent.bind.is"
            v-on="currentStateComponent.on"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions } from 'vuex';

const PaymentStatePolling = () => import('@/components/shared/modules/payment/PaymentStatePolling');
const PaymentStateSuccess = () => import('@/components/shared/modules/payment/PaymentStateSuccess');
const PaymentStateFailed = () => import('@/components/shared/modules/payment/PaymentStateFailed');

export default {
  name: 'PaymentFinalizationScreen',
  props: {},
  components: {},
  data: () => ({
    pollingId: undefined,
    activeStateIndex: 0,
    states: [
      {
        bind: {
          is: PaymentStatePolling,
        },
      },
      {
        bind: {
          is: PaymentStateSuccess,
          transactionId: undefined,
          emailAddress: undefined,
          voucherLink: undefined,
          invoiceLink: undefined,
        },
      },
      {
        bind: {
          is: PaymentStateFailed,
          transactionId: undefined,
          reason: undefined,
        },
      },
    ],
  }),
  created() {
    if (this.$route.query.r && this.$route.query.s) {
      this.handleSimpleBackMethod(this.$route.query.r, this.$route.query.s);
    }
  },
  computed: {
    currentStateComponent() {
      return this.states[this.activeStateIndex];
    },
  },
  methods: {
    ...mapActions({
      checkSimpleResponse: 'simple/checkSimpleResponse',
      checkOrderStatus: 'simple/checkOrderStatus',
      clearCart: 'cart/clearCart',
    }),
    cancelPollAction() {
      clearInterval(this.pollingId);
    },
    showPaymentFailedState(reason, transactionId) {
      this.cancelPollAction();

      this.activeStateIndex = 2;

      if (reason) {
        this.states[this.activeStateIndex].bind.reason = reason;
        this.states[this.activeStateIndex].bind.transactionId = undefined;
      } else if (transactionId) {
        this.states[this.activeStateIndex].bind.transactionId = transactionId;
        this.states[this.activeStateIndex].bind.reason = undefined;
      }
    },
    resetUsersCart() {
      this.clearCart(store.getters.cartId);
    },
    showPaymentSuccessState(payload) {
      this.cancelPollAction();

      this.states[1].bind.transactionId = payload.transactionId.toString();
      this.states[1].bind.emailAddress = payload.email;
      this.states[1].bind.voucherLink = payload.ticketDownloadLink;
      this.states[1].bind.invoiceLink = payload.invoiceDownloadLink;

      this.$router.replace('/payment/finalization');

      this.activeStateIndex = 1;
      this.resetUsersCart();
    },
    startPolling() {
      this.cancelPollAction();

      this.checkOrderStatus({
        orderId: store.getters.orderId,
      }).then((resp) => {
        if (resp.orderStatus === 'DECLINED') {
          this.showPaymentFailedState(this.$t(this.$PAYMENT_ERRORS.REASON[resp.orderReason]), undefined);
        } else if (resp.orderStatus === 'SUCCESS') {
          this.showPaymentSuccessState(resp);
        } else if (resp.orderStatus === 'PENDING') {
          this.pollingId = setInterval(() => {
            // TODO: only for test purposes
            console.log('polling ran');
            this.startPolling();
          }, 4000);
        }
      });
    },
    handleSimpleBackMethod(r, s) {
      this.checkSimpleResponse({
        orderId: store.getters.orderId,
        response: r,
        signature: s,
      }).then((resp) => {
        switch (resp.transactionResult) {
          case 'SUCCESS':
            this.startPolling();
            break;
          case 'CANCEL':
            this.showPaymentFailedState(this.$t(this.$PAYMENT_ERRORS.REASON[resp.transactionResult]), undefined);
            break;
          default:
            this.showPaymentFailedState(undefined, resp.transactionId);
            break;
        }
      });
    },
  },
  beforeDestroy() {
    this.cancelPollAction();
  },
};
</script>

<style lang='scss' scoped>
$contentWidth: 600px;
$contentHeight: 535px;

#payment-finalization-screen {
  padding: 40px 0 180px;

  @media screen and (max-width: $breakpointDownXs) {
    padding-bottom: 90px;
  }
}

header {
  margin: 0 0 54px;

  .title {
    @include titleMixin();
    font-size: 40px;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 28px;
    }
  }
}

.content-section {
  width: $contentWidth;
  height: $contentHeight;
  margin: 0 auto;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $breakpointDownSm) {
    width: 100%;
    height: calc(#{$contentHeight} - 100px);
  }

  @media screen and (max-width: $breakpointDownXs) {
    padding: 0 35px;
  }
}
</style>
